import React from 'react';
import Input from '../../components/Input/Input'
import Button from '../../components/Button/Button'

import'./Styles.scss';
import CardPreview from './../../components/CardPreview/CardPreview'
import Logo from './../../components/Logo/Logo'
import IconAlert from '../../components/IconAlert/IconAlert'
import PeriodInfo from './../../components/PeriodInfo/PeriodInfo'
//import BoxTransaction from './../../components/BoxTransaction/BoxTransaction'
import CardTransaction from '../../components/Transaction/CardTransaction'
import PartnerTransaction from '../../components/Transaction/PartnerTransaction'
import ListTransactions from '../../components/ListTransactions/ListTransactions';

const Styles = () => {
    return (
    <div className="Flex">
        <div>
            <Input defaultValue="" name="Titre" elementType="text" label="Titre" />
            <Input defaultValue="" name="Password" elementType="password" label="Password" />
            <Input defaultValue="" style={{textTransform:'uppercase'}} name="Masked" elementType="mask" label="Masked" mask="{****}-{****}-{****}-{****}" placeholder="XXXX-XXXX-XXXX-XXXX" />
        </div>
        <div>
            <Button variant="primary" text="Primaire" href="#" />
            <Button variant="secondary" text="Secondaire" href="#" />
            <Button variant="light" text="Blanc" href="#" />
            <Button variant="light" text="Avec icone" href="#" icon="logout" />
            <Button variant="primary" text="Active" href="#" className="is-active" />
        </div>
        <div style={{maxWidth:'450px'}}><CardPreview cardNumber="ABCDEFGHIJKLMNOP" amount="123.45" /></div>
        <div><Logo /></div>
        <div>
            <IconAlert type="success" message="Hello World" />
            <IconAlert type="danger" message="Hello World" />
        </div>
        <div><PeriodInfo total="123.45" commission="12.34" commissionPercent="15" bill="337.45" periods={null} /></div>
        <div><CardTransaction customerName="Estrimont Suite & Spa" amount="100.00" date="12/10/2020"/></div>
        <div><PartnerTransaction cardNumber="XBCVHDGSBHFJUJDY" amount="100.00" date="12/10/2020"/></div>
        <div><ListTransactions transactions={false} /></div>
    </div>
    )
}

export default Styles;