import React from 'react';

import motif_1 from "./../../assets/images/motif_1.png";
import motif_2 from "./../../assets/images/motif_2.png";
import motif_3 from "./../../assets/images/motif_3.png";

import './BackgroundMotifs.scss'

const BackgroundMotifs = () => {
    return (<div className="BackgroundMotifs">
        <img src={motif_1} alt="Motif 1" className="motif1" />
        <img src={motif_2} alt="Motif 2" className="motif2"  />
        <img src={motif_3} alt="Motif 3" className="motif3"  />
    </div>)
}

export default BackgroundMotifs;