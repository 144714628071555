import React, {useState, useEffect/*, useRef*/} from 'react';

import './PageSoldeLogin.scss';
import BackgroundMotifs from './../../components/BackgroundMotifs/BackgroundMotifs';
import Logo from './../../components/Logo/Logo'
import Input from "./../../components/Input/Input"
import Button from "./../../components/Button/Button"
import IconAlert from '../../components/IconAlert/IconAlert'

import axios from "axios";
import variables from "./../../variables.js";
import {navigate} from "@reach/router";

import {Helmet} from "react-helmet";

const PageSoldeLogin = () => {
    const [cardNumber,setCardNumber] = useState('');
    const [formState,setFormState] = useState('normal');
    const [formErrorMSG,setFormErrorMSG] = useState('');
    const [errorCodeChecked,setErrorCodeChecked] = useState(false);
    useEffect(() => {
        if(!errorCodeChecked){
            let checkErrorCode = localStorage.getItem('error_code');
            if(checkErrorCode == 'forbidden_access_from_card'){
                setFormState('error')
                setFormErrorMSG("Vous n'avez pas les permissions pour voir cette page.");
            }else if(checkErrorCode == 'cant_retrieve_transactions'){
                setFormState('error')
                setFormErrorMSG("Une erreur est survenue.");
            }
            setErrorCodeChecked(true);
        }
    },[formErrorMSG])
    const submitLogin = (e) => {
        if(e){
            e.preventDefault();
        }
        setFormState('locked')
        var cardNumberClean = cardNumber.replace(/-/g,'').toUpperCase();
        if(cardNumberClean === "" || cardNumberClean.length !== 16){
            setFormState('error');
            setFormErrorMSG('Veuillez saisir le numéro de carte')
            return false;
        }
        axios({
            method: 'post',
            url:variables.site+'wp-json/jwt-auth/v1/token',
            headers:{
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data:'username=balance&custom_auth='+cardNumberClean
            /*{
            username: 'balance',
            custom_auth: cardNumberClean,
            }*/
        }).then(function(response){
            //console.log(response);
            if(response.data.success){
                localStorage.setItem('token-card-solde',response.data.data.token);
                localStorage.setItem('number-card-solde',cardNumberClean);
                //var accountname = response.data.data.nicename;
                //if(!accountname){accountname = response.data.data.displayname;} 
                //localStorage.setItem('authsolde',accountname);
                setFormState('normal');
                localStorage.setItem('error_code', '');
                navigate('/solde/transactions/')
            }else{
                localStorage.setItem('error_code', '');
                if(response.data.message){
                    setFormErrorMSG(response.data.message)
                }
                setFormState('error')
            }
        }).catch(function(error){
            localStorage.setItem('error_code', '');
            //console.log(error);
            //console.log(error.response.data);
            localStorage.setItem('token','');
            localStorage.setItem('error','403');
            if(error.response.data.message){
                if(error.response.data.code == 'no_card_number'){
                    setFormErrorMSG('Veuillez saisir le numéro de carte.');    
                }else if(error.response.data.code == 'card_not_found'){
                    setFormErrorMSG("Le numéro de carte n'est pas valide.");    
                }
                setFormErrorMSG(error.response.data.message);
            }else{
                setFormErrorMSG('Une erreur est survenue lors de la consultation de notre base de données, veuillez réessayer plus tard.')
            }
            setFormState('error')
            //navigate('/solde/')
        })
    }
    const handleKeypress = e => {
        //it triggers by pressing the enter key
        //console.log(e);
      if (e.keyCode === 13 || e.charCode === 13) {
        submitLogin();
      }
    };
        return (<div className="PageSoldeLogin PageCardForm">
            <Helmet>
                <title>Forfaits Québec - Consulter le solde d'une carte</title>
            </Helmet>
            <BackgroundMotifs />
            <div className="form">
                <Logo color="white" />
                <IconAlert type="info" message="Inscrivez le code de votre carte cadeau dans le champ ci-bas pour connaître son solde." />
                {formState === 'error' && <IconAlert type="danger" message={formErrorMSG === '' ? 'Une erreur est survenue' : formErrorMSG} />}
                <form onSubmit={submitLogin}>
                    <Input autoComplete="password" onKeyPress={handleKeypress} value={cardNumber} style={{textTransform:'uppercase'}} name="CodeCarteCadeaux" elementType="mask" label="Numéro de carte" mask="{****}-{****}-{****}-{****}" placeholder="XXXX-XXXX-XXXX-XXXX" className="is-label-white" changed={(e) => {
                        //console.log('x');
                        //console.log(e.target.value);
                        if(e.type === 'change'){
                            setCardNumber(e.target.value);
                        }else{
                            var clipboardData = e.clipboardData || e.originalEvent.clipboardData || window.clipboardData;
                            var pastedData = clipboardData.getData('text');
                            var cleanup = pastedData.replace(/[^0-9a-z]/gi, '')
                            var elements = cleanup.match(/.{1,4}/g);
                            var cleanCardNb = '';
                            for(var i=0;i<4;i++){
                                if(i!==0){cleanCardNb += '-';}
                                cleanCardNb+=elements[i];
                            }
                            setCardNumber(cleanCardNb);
                        }
                    }} />
                    <Button text="Vérifiez mon solde" block size="lg"  onClick={submitLogin} className={formState === 'locked' && 'is-active'} />
                </form>
            </div>
        </div>)
}

export default PageSoldeLogin;