import React, {useState,useEffect,createRef} from 'react';

import Logo from './../../components/Logo/Logo'
import Button from "./../../components/Button/Button"
import ListTransactions from '../../components/ListTransactions/ListTransactions';
import PeriodInfo from './../../components/PeriodInfo/PeriodInfo'
import BackgroundMotifs from './../../components/BackgroundMotifs/BackgroundMotifs';
import {navigate} from "@reach/router";

import {Helmet} from "react-helmet";
import axios from "axios";
import variables from "./../../variables.js";

import './PagePartner.scss'

const PagePartner = () => {
    const [currMonth,setCurrMonth] = useState('');
    const [transactions,setTransactions] = useState(null);
    const [periodTotal,setPeriodTotal] = useState('');
    const [periodCommission,setPeriodCommission] = useState('');
    const [periodCommissionPercent,setPeriodCommissionPercent] = useState('');
    const [periodBill,setPeriodBill] = useState('');
    const token = localStorage.getItem('token');
    useEffect(()=>{
        if(currMonth && !periodTotal){
            var date = new Date();
            axios({
                method:'get',
                url:variables.site+'wp-json/carte-cadeau/v1/partner-transactions/'+currMonth+'?timestamp='+date.getTime(),
                headers:{
                    'Authorization': 'Bearer ' + token
                },
                withCredentials: true,
                credentials: 'include',
            }).then(function(response){
                //console.log(response);
                if(response.data.success !== false && response.data.success !== "false"){
                    //console.log("success")
                    //console.log(response.success)
                    if(response.data){
                        setTransactions(response.data.transactions);
                        setPeriodTotal(response.data.totalAmount);
                        setPeriodCommission(response.data.commissionAmount);
                        setPeriodCommissionPercent(response.data.commissionPercent);
                        setPeriodBill(response.data.billedAmount);
                        window.setTimeout(()=>{
                            console.log('Checking Token Validity')
                            setPeriodTotal("");
                        },30*1000)
                    }
                }else{
                    localStorage.setItem('token','');
                    localStorage.setItem('error','tokenerror');
                    navigate('/partenaire/')
                }
            }).catch(function(error){
                localStorage.setItem('token','');
                localStorage.setItem('error','403');
                navigate('/partenaire/')
            })
        }
    },[currMonth,periodTotal,periodCommissionPercent,periodCommission,periodBill,token])
    const Logout = () => {
        localStorage.setItem('token','');
        navigate('/partenaire/')
    }
    if(!localStorage.getItem('token')){
        navigate('/partenaire/')
    }
    var periods = [];
    var d = new Date();
    var annee = d.getFullYear();
    var month = d.getMonth()+1;
    var firstOpt = '';
    for(var i=0;i<18;i++){
        var monthname = '';
        var monthnb = '';
        switch(month){
            case 1:
                monthname='Janvier';
                monthnb='01';
                break;
            case 2:
                monthname='Février';
                monthnb='02';
                break;
            case 3:
                monthname='Mars';
                monthnb='03';
                break;
            case 4:
                monthname='Avril';
                monthnb='04';
                break;
            case 5:
                monthname='Mai';
                monthnb='05';
                break;
            case 6:
                monthname='Juin';
                monthnb='06';
                break;
            case 7:
                monthname='Juillet';
                monthnb='07';
                break;
            case 8:
                monthname='Août';
                monthnb='08';
                break;
            case 9:
                monthname='Septembre';
                monthnb='09';
                break;
            case 10:
                monthname='Octobre';
                monthnb='10';
                break;
            case 11:
                monthname='Novembre';
                monthnb='11';
                break;
            case 12:
                monthname='Décembre';
                monthnb='12';
                break;
            default:
            break;
        }
        if(firstOpt === ''){
            firstOpt = annee+'/'+monthnb;
        }
        periods.push({
            val:annee+'/'+monthnb,
            option:monthname+' '+annee
        });
        month--;
        if(month===0){
            month=12;
            annee--;
        }
    }
    if(currMonth === ''){
        setCurrMonth(firstOpt);
    }
    const linkExport = createRef();
    var filename = '';
    const exportAction = async()=> {
        if (linkExport.current.href) { return }
  
        const result = await fetch(linkExport, {	
            headers: {
                'Authorization': 'Bearer ' + token
            },
            withCredentials: true,
            credentials: 'include',
        })
        
        const blob = await result.blob()
        const href = window.URL.createObjectURL(blob)
        
        linkExport.current.download = filename
        linkExport.current.href = href
        
        linkExport.current.click()
    }
    return (<div className="PagePartenaire">
        <Helmet>
            <title>Forfaits Québec - Liste des transactions</title>
        </Helmet>
        <BackgroundMotifs />
        <div className="container">
            <div className="row">
                <div className="col-4">
                    <Logo />
                </div>
                <div className="col-8 text-right">
                    <Button variant="light" text="Déconnexion" href="#" icon="logout" size="sm" onClick={Logout} />
                </div>
            </div>
            <h2>{localStorage.getItem('accountname')}</h2>
            <PeriodInfo 
                total={periodTotal}
                commission={periodCommission}
                commissionPercent={periodCommissionPercent}
                bill={periodBill}
                periods={periods}
                value={currMonth}
                exportRef={linkExport}
                exportAction={exportAction}
                onValueChange={(e)=>{
                    setTransactions(null)
                    setPeriodTotal('');
                    setPeriodCommission('');
                    setPeriodCommissionPercent('');
                    setPeriodBill('');
                    setCurrMonth(e.target.value)
                }} />
            <ListTransactions transactions={transactions} />
        </div>
    </div>)
}

export default PagePartner;