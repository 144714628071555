var site = 'https://www.forfaitsquebec.com/';
if (window.location.href.indexOf("pixelcircus") > -1 || window.location.href.indexOf("localhost") > -1 || window.location.href.indexOf("stage.") > -1) {
    console.warn(
        '%c%s',
        'font-size:24px;font-weight:700;',
        'Stage loadé!'
    );
    site = 'https://fq.pixelcircusclient.com/';
}
var variables = {
    site: site
}
export default variables;