import React from 'react';
import './PartnerTransaction.scss';

import CardCode from './../CardCode/CardCode';

const PartnerTransaction = (props) => {
    const Title = (props) => {
        if(props.cardNumber){
            return <CardCode cardNumber={props.cardNumber} />;
        }else if(props.partner){
            return <b>{props.partner}</b>
        }
    }
    return (
        <div className="PartnerTransaction">
            <div className="details">
                <span><Title cardNumber={props.cardNumber} partner={props.partenaire} /></span>
                <span className="amount">{props.amount}$</span>
            </div>
            <div className="date">
                {props.date}
            </div>
        </div>
    )
}

export default PartnerTransaction;