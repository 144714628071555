import React from 'react';
import './CardCode.scss';

const CardCode = (props) => {
    var key=0;
    var {cardNumber = false} = props;
    if(!cardNumber){
        return false;
    }else{
    const codeSplit = cardNumber.match(/.{1,4}/g);
        return <div className="CardCode">{codeSplit.map(codeEl => {key++; return(<div key={key}>{codeEl}</div>);})}</div>
    }
}

export default CardCode;