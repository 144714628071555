import React, {useState} from 'react';
import Input from './../Input/Input';
import Button from './../Button/Button';
import Icon from './../Icon/Icon';
import './PeriodInfo.scss';

import axios from 'axios'
import fileDownload from 'js-file-download'
import variables from "./../../variables.js";


const PeriodInfo = (props) => {
    const {periods,total,commission,commissionPercent,bill,onValueChange = () => {},value=''} = props;
    const token = localStorage.getItem('token');
    const [exporting, setExporting] = useState(false);

    const px_download_export = (month) => {
        //console.log(value);
        if(!exporting){
            setExporting(true);
            axios({
                method:'get',
                url:variables.site+'wp-json/carte-cadeau/v1/partner-transactions-export/'+value,
                responseType: 'blob',
                headers:{
                    'Authorization': 'Bearer ' + token
                }
            }).then(function(response){
                //console.log(response)
                fileDownload(response.data, 'export-'+value.replaceAll('/','-')+'.csv')
                setExporting(false);
            })
        }
    }
    return (<div className="PeriodInfo">
        <div className="selection row">
            <div className="period col-8"><Input prepend={<Icon icon="calendar" />} hideLabel={true} elementType="select" choices={periods} changed={onValueChange} value={value} /></div>
            <div className="export col-4"><Button text="Exporter" icon="download" className={exporting ? 'is-active' : ''} href="#" onClick={()=>{px_download_export(value)}} size="sm" block /></div>
        </div>
        {total !== '' && 
            <div className="information row no-gutters">
                <div className="col-8"><b>Total</b></div>
                <div className="col-4 text-right"><b>{total} $</b></div>
            </div>   
        }
        {commission !== '' &&
            <div className="information row no-gutters">
                <div className="col-8">Commission ({commissionPercent}% + taxes)</div>
                <div className="col-4 text-right"><b>{commission} $</b></div>
            </div>
        }
        {bill !== '' &&
            <div className="information row no-gutters">
                <div className="col-8">À facturer</div>
                <div className="col-4 text-right"><b>{bill} $</b></div>
            </div>
        }
            
    </div>)
}

export default PeriodInfo;