import React, {useState, useEffect, useRef} from 'react';

import './PageCardForm.scss';
import BackgroundMotifs from './../../components/BackgroundMotifs/BackgroundMotifs';
import Logo from './../../components/Logo/Logo'
import Input from "./../../components/Input/Input"
import Button from "./../../components/Button/Button"
import IconAlert from '../../components/IconAlert/IconAlert'

import axios from "axios";
import variables from "./../../variables.js";
import {navigate} from "@reach/router";

import {Helmet} from "react-helmet";

const PageCardForm = () => {
    const [identifiant,setIdentifiant] = useState('');
    const [cardNumber,setCardNumber] = useState('');
    const [formState,setFormState] = useState('normal');
    const [formErrorMSG,setFormErrorMSG] = useState('');
    const [errorCodeChecked,setErrorCodeChecked] = useState(false);
    useEffect(() => {
        if(!errorCodeChecked){
            let checkErrorCode = localStorage.getItem('error_code');
            if(checkErrorCode == 'forbidden_access'){
                setFormState('error')
                setFormErrorMSG("Vous n'avez pas les permissions pour voir cette page.");
            }else if(checkErrorCode == 'card_number_not_found'){
                setFormState('error')
                setFormErrorMSG("Le numéro de carte n'est pas valide.");
            }else if(checkErrorCode == 'no_amount' || checkErrorCode == 'empty_amount'){
                setFormState('error')
                setFormErrorMSG("Veuillez saisir un montant.");
            }else if(checkErrorCode == 'invalid_amount'){
                setFormState('error')
                setFormErrorMSG("Le montant n'est pas valide.");
            }else if(checkErrorCode == 'not_enough_balance'){
                setFormState('error')
                setFormErrorMSG("Le montant sur la carte est insuffisant.");
            }else if(checkErrorCode == 'full_debit_only'){
                setFormState('error')
                setFormErrorMSG("Les cartes Forfait doivent être débitées en entier.");
            }else if(localStorage.getItem('error')==='403'){
                setFormState('error');
            }
            setErrorCodeChecked(true);
        }
    },[formErrorMSG])
    const submitLogin = (e) => {
        if(e){
            e.preventDefault();
        }
        setFormState('locked')
        if(identifiant === ""){
            setFormState('error');
            setFormErrorMSG('Veuillez saisir votre identifiant')
            return false;
        }
        var cardNumberClean = cardNumber.replace(/-/g,'').toUpperCase();
        if(cardNumberClean === "" || cardNumberClean.length !== 16){
            setFormState('error');
            setFormErrorMSG('Veuillez saisir le numéro de carte')
            return false;
        }
        axios.post(variables.site+'wp-json/jwt-auth/v1/token',{
            username: identifiant,
            custom_auth: cardNumberClean,
        }).then(function(response){
            //console.log(response);
            if(response.data.success){
                localStorage.setItem('token-card',response.data.data.token);
                var accountname = response.data.data.nicename;
                if(!accountname){accountname = response.data.data.displayname;} 
                localStorage.setItem('accountname',accountname);
                setFormState('normal');
                localStorage.setItem('error_code', '');
                navigate('/debiting/')
            }else{
                if(response.data.message){
                    setFormErrorMSG(response.data.message)
                }
                setFormState('error')
            }
        }).catch(function(error){
            console.log(error);
            localStorage.setItem('token','');
            setFormState('normal');
            localStorage.setItem('error_code', '');
            localStorage.setItem('error','403');
            if(error.response){
                console.log(error.response.data.code);
                if(error.response.data.code == "bad_username_card_combination1"){
                    setFormErrorMSG("Votre nom d'utilisateur est invalide");
                }else if(error.response.data.code == 'bad_username_card_combination2'){
                    setFormErrorMSG('Le code de carte fourni est invalide.');
                }else if(error.response.data.code == "no_lock"){
                    setFormErrorMSG("Ce numéro de carte est présentement verrouillé. Veuillez réessayer dans une quinzaine de minutes.");
                }else if(error.response.data.code == "invalid_ip" || error.response.data.code == "missing_ip"){
                    setFormErrorMSG("Une erreur est survenue.");
                }
            }
            navigate('/')
        })
    }
    const handleKeypress = e => {
        //it triggers by pressing the enter key
        //console.log(e);
        if (e.keyCode === 13 || e.charCode === 13) {
            submitLogin();
        }
    };
    return (<div className="PageCardForm">
        <Helmet>
            <title>Forfaits Québec - Paiement via une carte cadeau</title>
        </Helmet>
        <BackgroundMotifs />
        <div className="form">
            <Logo color="white" />
            {formState === 'error' && <IconAlert type="danger" message={formErrorMSG === '' ? 'Une erreur est survenue' : formErrorMSG} />}
            <form >
                <Input autoComplete="username" onKeyPress={handleKeypress} value={identifiant} name="Identifiant" elementType="text" label="Identifiant" className="is-label-white" changed={(e) => {
                    setIdentifiant(e.target.value);
                }} />
                <Input autoComplete="password" onKeyPress={handleKeypress} value={cardNumber} style={{textTransform:'uppercase'}} name="CodeCarteCadeaux" elementType="mask" label="Numéro de carte" mask="{****}-{****}-{****}-{****}" placeholder="XXXX-XXXX-XXXX-XXXX" className="is-label-white" changed={(e) => {
                    //console.log('x');
                    //console.log(e.target.value);
                    if(e.type === 'change'){
                        setCardNumber(e.target.value);
                    }else{
                        var clipboardData = e.clipboardData || e.originalEvent.clipboardData || window.clipboardData;
                        var pastedData = clipboardData.getData('text');
                        var cleanup = pastedData.replace(/[^0-9a-z]/gi, '')
                        var elements = cleanup.match(/.{1,4}/g);
                        var cleanCardNb = '';
                        for(var i=0;i<4;i++){
                            if(i!==0){cleanCardNb += '-';}
                            cleanCardNb+=elements[i];
                        }
                        setCardNumber(cleanCardNb);
                    }
                }} />
                <Button text="Connexion" block size="lg"  onClick={submitLogin} className={formState === 'locked' && 'is-active'} />
            </form>
        </div>
    </div>)
}

export default PageCardForm;