import React from 'react';
import {Button} from 'react-bootstrap';

import Icon from './../Icon/Icon';

//import IconLogout from './../../logo.svg';

import './Button.scss';

const PixButton = (props) => {
    const {text = "Setup Texte!",icon = false} = props;
    if(!icon){
        return (
        <Button 
        {...props}
        >
            {text}
        </Button>)
    }else{
        return (
        <Button 
        {...props}
        >
            {text}
            <Icon icon={icon} />
        </Button>)
    }
}

export default PixButton;