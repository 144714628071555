import React from 'react';
import PartnerTransaction from '../../components/Transaction/PartnerTransaction'
import IconAlert from '../../components/IconAlert/IconAlert'
import './ListTransactions.scss';

const ListTransactions=(props)=>{
    const {transactions} = props;
    var listtransaction = transactions;
    /*if(!listtransaction){
        listtransaction = [
            {
                id: 1,
                cardNumber: 'EXAMPLE0TRANSACT',
                date: '99/99/9999',
                amount: '0.00'
            },
            {
                id: 2,
                cardNumber: 'EXAMPLE0TRANSACT',
                date: '99/99/9999',
                amount: '0.00'
            },
            {
                id: 3,
                cardNumber: 'EXAMPLE0TRANSACT',
                date: '99/99/9999',
                amount: '0.00'
            }
        ]
        listtransaction = false;
    }*/
    if(listtransaction === null){
        return (<IconAlert type="light" message="En chargement" />);
    }
    else if(Array.isArray(listtransaction) && listtransaction.length !== 0){
        return (<div className="ListTransactions">
            {listtransaction.map(trans => <PartnerTransaction key={trans.id} cardNumber={trans.cardNumber} partenaire={trans.display_name} amount={trans.amount} date={trans.date} />)}
        </div>)
    }else{
        return (<IconAlert type="danger" message="Aucune transaction n'a été trouvée pour la période sélectionnée." />);
    }
}

export default ListTransactions