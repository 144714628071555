import React from 'react';

import {ReactComponent as Logout} from './../../assets/images/icon-logout.svg';
import {ReactComponent as Check} from './../../assets/images/icon-check.svg';
import {ReactComponent as Warning} from './../../assets/images/icon-warning.svg';
import {ReactComponent as Download} from './../../assets/images/icon-download.svg';
import {ReactComponent as Calendar} from './../../assets/images/icon-calendar.svg';

import './Icon.scss';

const Icon = (props) => {
    switch(props.icon){
        case 'logout':
            return <Logout />
        case 'check':
        case 'success':
            return <Check />
        case 'warning':
        case 'danger':
            return <Warning />
        case 'download':
            return <Download />
        case 'calendar':
            return <Calendar />
        //case 'light':
        case 'loading':
            return <div className="Icon is-loading" />
        default:
            return (<div></div>)
    }
}

export default Icon;