import React from 'react';
import {Alert} from 'react-bootstrap';
import Icon from '../Icon/Icon'

import './IconAlert.scss';

const IconAlert = (props) => {
    const {type,message} = props;
    return (
        <Alert className={"IconAlert alert-"+type}>
            <Icon icon={type} />
            <span>{message}</span>
        </Alert>
    )
}

export default IconAlert;