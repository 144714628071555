import React, { useState, useEffect } from 'react';
import './PageDebiting.scss'

import Input from '../../components/Input/Input'
import Button from '../../components/Button/Button'
import CardPreview from '../../components/CardPreview/CardPreview'
import IconAlert from '../../components/IconAlert/IconAlert'
import Transaction from '../../components/Transaction/CardTransaction';
import { navigate } from "@reach/router";
import BackgroundMotifs from './../../components/BackgroundMotifs/BackgroundMotifs';

import axios from "axios";
import variables from "./../../variables.js";

import { Helmet } from "react-helmet";

const PageDebiting = () => {
    const [cardBalance, setCardBalance] = useState('0.00');
    const [cardNumber, setCardNumber] = useState('CHARGEMENT-CARTE');
    const [amount, setAmount] = useState('');
    const [formState, setFormState] = useState('locked');
    const token = localStorage.getItem('token-card');
    const [cardType, setCardType] = useState('normal')
    const [cardTextDescription, setCardTextDescription] = useState('')
    const [checkingToken, setCheckingToken] = useState(false);
    var pixheaders = {
        'Authorization': 'Bearer ' + token,
        //"Access-Control-Allow-Origin": 'https://www.forfaitsquebec.com',
        //'Vary': 'Origin'
    };
    function addZeroes(num) {
        const dec = num.split('.')[1]
        const len = dec && dec.length > 2 ? dec.length : 2
        return Number(num).toFixed(len)
    }
    useEffect(() => {
        console.log(checkingToken);
        if (cardBalance === '0.00' || checkingToken) {
            setCheckingToken(false);
            //console.log(pixheaders);
            var date = new Date();
            axios({
                method: 'get',
                url: variables.site + 'wp-json/carte-cadeau/v1/card-info?timestamp='+date.getTime(),
                headers: pixheaders,
                withCredentials: true,
                credentials: 'include',
            }).then(function (response) {
                //console.log(response);
                if (response.status === 200) {
                    console.log(response);
                    if (response.data.success == false && response.data.code == 'jwt_auth_invalid_token') {
                        console.log('Token invalide: ' + localStorage.getItem('token-card'));
                        localStorage.setItem('token-card', '');
                        localStorage.removeItem('token-card');
                        localStorage.setItem('error', '403');
                        navigate('/');
                    }
                    setCardBalance(response.data.balance);
                    setCardNumber(response.data.cardNumber);
                    setFormState('normal')
                    window.setTimeout(()=>{
                        console.log('Checking Token Validity')
                        setCheckingToken(true);
                    },30*1000)
                    if (response.data.cardNumber) {
                        localStorage.setItem('error', '');
                        localStorage.removeItem('error');
                        if (response.data.isForfait) {
                            console.log(response.data);
                            setCardType('forfaitCadeau')
                            setAmount(response.data.balance);
                            setCardTextDescription(response.data.description)
                        }
                    }
                } else {
                    localStorage.setItem('token-card', '');
                    localStorage.removeItem('token-card');
                    navigate('/');
                }
            }).catch(function(error){
                console.log(error)
                formState('normal')
                localStorage.setItem('token-card', '');
                localStorage.removeItem('token-card');
                localStorage.setItem('error', '403');
                if(error.response){
                    console.log(error.response.data.code);
                    localStorage.setItem('error_code', error.response.data.code);
                }
                navigate('/');
            })
        }
    },[cardBalance,checkingToken])
    const Logout = () => {
        localStorage.setItem('token-card', '');
        localStorage.removeItem('token-card');
        navigate('/')
    }
    const Submit = (e) => {
        e.preventDefault();
        if (!amount || parseFloat(amount) < 0.01) {
            setFormState('input_error')
            return false;
        }
        if (parseFloat(amount) > parseFloat(cardBalance)) {
            console.log(parseFloat(amount));
            console.log(parseFloat(cardBalance));
            setFormState('balance_error')
            return false;
        }
        setFormState('locked');
        axios(
            {
                method: 'post',
                url: variables.site + 'wp-json/carte-cadeau/v1/card-debit',
                data: {
                    amount: addZeroes(amount),
                },
                headers: pixheaders,
                withCredentials: true,
                credentials: 'include',
            }
        ).then(function (response) {
            //console.log(response);
            if (response.data.balance) {
                setCardBalance(response.data.balance);
                setFormState('success')
            } else {
                setFormState('error');
            }
        }).catch(function (error) {
            //console.log(error);
            //console.log(error.response.request._response)
            setFormState('error');
        })
    }
    var date = new Date();
    return (
        <div className="PageDebiting">
            <Helmet>
                <title>Forfaits Québec - Payer via une carte cadeau</title>
            </Helmet>
            <BackgroundMotifs />
            <div className="innerPageDebiting">
                <div className="text-right">
                    <Button variant="light" text="Déconnexion" href="#" icon="logout" size="sm" onClick={Logout} />
                </div>
                <CardPreview cardNumber={cardNumber} amount={cardBalance} />
                {formState === 'success' ?
                    <div>
                        <IconAlert type="success" message="Transaction acceptée" />
                        <Transaction amount={amount} date={date.getUTCDate() + "/" + (date.getUTCMonth() + 1) + '/' + date.getUTCFullYear()} customerName={localStorage.getItem('accountname')} />
                    </div>
                    :
                    <form onSubmit={Submit}>
                        {cardTextDescription !== '' ? <div className="description" dangerouslySetInnerHTML={{ __html: cardTextDescription }}></div> : <div></div>}
                        {cardType === 'forfaitCadeau' && amount !== "0.00" ? <IconAlert type="light" message="Le montant à débiter n'est pas modifiable." /> : ((amount === "0.00") ? <IconAlert type="danger" message="Ce certificat a déjà été utilisé" /> : '')}
                        <Input
                            autoComplete="amount"
                            value={amount}
                            name="montantDebiter"
                            elementType="number"
                            step="0.01"
                            label="Montant à débiter"
                            append="$"
                            disabled={cardType === 'forfaitCadeau' ? true : false}
                            changed={(e) => {
                                setAmount(e.target.value);
                            }}
                        />
                        {formState === 'balance_error' && <IconAlert type="danger" message="Le montant sur la carte est insuffisant" />}
                        {formState === 'input_error' && <IconAlert type="danger" message="Veuillez saisir un montant" />}
                        {formState === 'error' && <IconAlert type="danger" message="Une erreur est survenue" />}
                        <Button variant="secondary" text="Débiter" block onClick={Submit} className={formState === 'locked' && 'is-active'} />
                    </form>
                }
            </div>
        </div>
    )
}

export default PageDebiting;