import React from 'react';
import './CardTransaction.scss';

const CardTransaction = (props) => {
    return (
        <div className="CardTransaction">
            <div className="details">
                <span>{props.customerName}</span>
                <span className="amount">{props.amount}$</span>
            </div>
            <div className="date">
                {props.date}
            </div>
        </div>
    )
}

export default CardTransaction;