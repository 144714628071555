import React, {useState} from 'react';
import './Input.scss';

import {IMaskInput} from 'react-imask';

const Input = (props) => {
    const {
        className="",
        elementType,
        label,
        defaultValue = "",
        changed,
        hideLabel=false,
        choices = [
            {
                val:'',
                option:'Fournir des choix.'
            }
        ],
        mask='',
        append=false,
        prepend=false,
        ...rest
    } = props;
    //delete props.append;
    const [elementValue,setElementValue] = useState(defaultValue);
    var onChangeFunction = changed;
    if(!onChangeFunction){
        onChangeFunction = (e) => {
            setElementValue(e.target.value);
        }
    }
    var InputElement = null;
    switch (elementType){
        case 'textarea':
            InputElement = (
                <textarea 
                    onChange={onChangeFunction}
                    value={elementValue}
                    {...rest}
                >{elementValue}</textarea>
            )
            break;
        case 'select':
            InputElement = (
                    <select 
                        onChange={onChangeFunction}
                        value={elementValue}
                        {...rest}
                    >
                        {choices && choices.map((choice) => { 
                            return (<option key={choice.val} value={choice.val}>{choice.option}</option>)
                        })}
                    </select>
                )
                break;
        case 'mask':
            InputElement = (
                <IMaskInput 
                    mask={mask}
                    value={elementValue}
                    onChange={onChangeFunction}
                    onPaste={onChangeFunction}
                    {...rest}
                />
            )
            break;
        default :
            InputElement = (
                <input 
                    value={elementValue}
                    type={elementType}
                    onChange={onChangeFunction}
                    {...rest}
                />
            )
            break;
    }
    var classesGroup='';
    if(prepend){
        classesGroup+=' has-prepend';
    }
    if(append){
        classesGroup+=' has-append';
    }
    return (
        <div className={"Input " + className}>
            {!hideLabel && 
                <label>{label}</label>
            }
            <div className={"input-group " + classesGroup}>
                {prepend && 
                    <div className="input-group-prepend">
                        {prepend}
                    </div>
                }
                {InputElement}
                {append && 
                    <div className="input-group-append">
                        {append}
                    </div>
                }
            </div>
        </div>
    )
}

export default Input;