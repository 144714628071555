import React from 'react';

import Logo from './../../components/Logo/Logo';
import CardCode from './../CardCode/CardCode';

import './CardPreview.scss';

const CardPreview = (props) => {
    const {cardNumber,amount = '0.00'} = props;
    const amountArray = amount.split('.');
    //var splitamount = amountarray[0]+'<sub>'+amountarray[1]+'</sub>';
    //splitamount = 0;
    return (<div className="CardPreview">
        <div className="CardPreview-inner">
            <Logo size="sm" color="white" />
            <div className="amount">{amountArray[0]}<sub>,{amountArray[1]}</sub><sup>$</sup></div>
            <CardCode cardNumber={cardNumber} />
        </div>
    </div>)
}

export default CardPreview;