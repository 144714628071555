import React, {useState} from 'react';

import './LoginPartner.scss'
import BackgroundMotifs from '../../components/BackgroundMotifs/BackgroundMotifs';
import Logo from '../../components/Logo/Logo';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import IconAlert from '../../components/IconAlert/IconAlert'

import axios from "axios";
import variables from "./../../variables.js";
import {navigate} from "@reach/router";

import {Helmet} from "react-helmet";

const LoginPartner = () => {
    const [identifiant,setIdentifiant] = useState('');
    const [motDePasse,setMotDePasse] = useState('');
    const [formState,setFormState] = useState('normal');
    const [formErrorMSG,setFormErrorMSG] = useState('');
        
    const submitLogin = (e) => {
        if(e){
            e.preventDefault();
        }
        localStorage.setItem('error','')
        setFormState('locked')
        //console.log(identifiant)
        if(identifiant === ""){
            setFormState('error');
            setFormErrorMSG('Veuillez renseigner votre identifiant')
            return false;
        }
        if(motDePasse === ""){
            setFormState('error');
            setFormErrorMSG('Veuillez renseigner votre mot de passe')
            return false;
        }
        axios({
            method:'post',
            url:variables.site+'wp-json/jwt-auth/v1/token',
            data:{
                username: identifiant,
                password: motDePasse,
            }
        }).then(function(response){
            if(response.data.success){
                localStorage.setItem('token',response.data.data.token);
                localStorage.setItem('error','');
                var accountname = response.data.data.nicename;
                if(!accountname){accountname = response.data.data.displayname;} 
                localStorage.setItem('accountname',accountname);
                setFormState('normal');
                navigate('/partenaire/transactions/')
            }else{
                //console.log(response)
                if(response.data.code === 'incorrect_password'){
                    setFormErrorMSG("Erreur: L'identifiant et/ou le mot de passe entrés sont incorrects.")
                }else if(response.data.message){
                    setFormErrorMSG(response.data.message)
                }
                setFormState('error')
            }
        }).catch(function(error){
            console.log(error);
            //setFormErrorMSG(error);
            setFormState('error');
        })
    }

    if(localStorage.getItem('error') === '403' && formState !== 'error'){
        setFormState('error');
        localStorage.removeItem('error')
        setFormErrorMSG("Le compte connecté n'a pas accès aux cartes cadeaux.");
        //localStorage.setItem('error','')
        return false;
    }
    if(localStorage.getItem('error') === 'tokenerror' && formState !== 'error'){
        setFormState('error');
        localStorage.removeItem('error')
        setFormErrorMSG("Votre session a expiré. Veuillez vous reconnecter.");
        //localStorage.setItem('error','')
        return false;
    }
    if(localStorage.getItem('token')){
        navigate('/partenaire/transactions/');
    }
    const handleKeypress = e => {
        //it triggers by pressing the enter key
        //console.log(e);
      if (e.keyCode === 13 || e.charCode === 13) {
        submitLogin();
      }
    };
    return (<div className="LoginPartenaire">
        <Helmet>
            <title>Forfaits Québec - Connexion d'un partenaire</title>
        </Helmet>
        <BackgroundMotifs />
        <div className="form">
            <Logo color="blue" />
            {formState === 'error' && <IconAlert type="danger" message={formErrorMSG === '' ? 'Une erreur est survenue' : formErrorMSG} />}
            <form onSubmit={submitLogin}>
                <Input onKeyPress={handleKeypress} autoComplete="username" value={identifiant} name="Identifiant" elementType="text" label="Identifiant" className="" changed={(e) => {
                    setIdentifiant(e.target.value);
                }} />
                <Input onKeyPress={handleKeypress} autoComplete="password" value={motDePasse} name="MotDePasse" elementType="password" label="Mot de passe" changed={(e) => {
                    setMotDePasse(e.target.value);
                }} />
                <Button text="Connexion" block size="lg" onClick={submitLogin} className={formState === 'locked' && 'is-active'} />
            </form>
        </div>
    </div>)
}

export default LoginPartner;