import React, {useState,useEffect/*,createRef*/} from 'react';

import Logo from './../../components/Logo/Logo'
import Button from "./../../components/Button/Button"
import ListTransactions from '../../components/ListTransactions/ListTransactions';
//import PeriodInfo from './../../components/PeriodInfo/PeriodInfo'
import CardPreview from '../../components/CardPreview/CardPreview'
import BackgroundMotifs from './../../components/BackgroundMotifs/BackgroundMotifs';
import {navigate} from "@reach/router";

import {Helmet} from "react-helmet";
import axios from "axios";
import variables from "./../../variables.js";

import './PageSoldeTransactions.scss'

const PageSoldeTransactions = () => {
    //const [currMonth,setCurrMonth] = useState('');
    const [transactions,setTransactions] = useState(null);
    const [cardNumber,setCardNumber] = useState('ENCHARGEMENT0000');
    const [cardBalance,setCardBalance] = useState('0.00');
    /*const [periodTotal,setPeriodTotal] = useState('');
    const [periodCommission,setPeriodCommission] = useState('');
    const [periodCommissionPercent,setPeriodCommissionPercent] = useState('');
    const [periodBill,setPeriodBill] = useState('');*/
    const token = localStorage.getItem('token-card-solde');
    useEffect(()=>{
        //console.log(token);
        //console.log(variables.site+'wp-json/carte-cadeau/v1/card-balance/');
        setCardNumber(localStorage.getItem('number-card-solde'))
        var date = new Date();
        axios({
            method:'get',
            url:variables.site+'wp-json/carte-cadeau/v1/card-balance/?timestamp='+date.getTime(),
            headers:{
                'Authorization': 'Bearer ' + token
            },
            withCredentials: true,
            credentials: 'include',
        }).then(function(response){
            //console.log(response);
            if(response.data){
                setTransactions(response.data.transactions);
                setCardBalance(response.data.balance)
                //setCardNumber(localStorage.getItem('number-card-solde'))
            }else{
                
            }
        }).catch(function(error){
            console.error('Query Error. Information en dessous.')
            console.error(error);
            localStorage.setItem('token-card-solde','');
            localStorage.removeItem('token-card-solde');
            localStorage.setItem('error','403');
            navigate('/solde/')
            if(error.response){
                console.log(error.response.data.code);
                localStorage.setItem('error_code', error.response.data.code);
            }
        })
    },[token])
    const Logout = () => {
        localStorage.setItem('token-card-solde','');
        localStorage.removeItem('token-card');
        navigate('/solde/')
    }
    if(!localStorage.getItem('token-card-solde')){
        navigate('/solde/')
    }
    return (<div className="PagePartenaire PageSoldeTransactions">
        <Helmet>
            <title>Forfaits Québec - Liste des transactions</title>
        </Helmet>
        <BackgroundMotifs />
        <div className="container">
            <div className="row">
                <div className="col-4">
                    <Logo />
                </div>
                <div className="col-8 text-right">
                    <Button variant="light" text="Déconnexion" href="#" icon="logout" size="sm" onClick={Logout} />
                </div>
            </div>
            <CardPreview cardNumber={cardNumber} amount={cardBalance} />
            <ListTransactions transactions={transactions} />
        </div>
    </div>)
}

export default PageSoldeTransactions;