//import logo from './logo.svg';
//import './App.css';
import React from "react";
import {Router} from "@reach/router";

import Styles from './templates/Styles/Styles.js';

import PageDebiting from './templates/PageDebiting/PageDebiting.js';
import PageCardForm from './templates/PageCardForm/PageCardForm.js';
import PagePartner from './templates/PagePartner/PagePartner.js';
import LoginPartner from './templates/LoginPartner/LoginPartner.js';
import PageSoldeLogin from './templates/PageSoldeLogin/PageSoldeLogin.js';
import PageSoldeTransactions from './templates/PageSoldeTransactions/PageSoldeTransactions.js';

import {Helmet} from "react-helmet";
import favicon from "./assets/images/favicon.png";

import './assets/scss/general.scss';

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>Forfaits Québec</title>
        <meta name="theme-color" content="#5dbaf3" />
        <link rel="icon" type="image/png" href={favicon} />
        <meta name="description" content="Microsite pour l'utilisation des cartes cadeaux Forfaits Québec." />
      </Helmet>
      <Router>
        <PageCardForm path="/" />
        <PageDebiting path="/debiting/" />
        <LoginPartner path="/partenaire/" />
        <PagePartner path="/partenaire/transactions/" />
        <PageSoldeLogin path="/solde/" />
        <PageSoldeTransactions path="/solde/transactions/" />
        <Styles path="/styles" />
      </Router>
    </div>
  );
}

export default App;
